import * as React from "react";
import { graphql } from "gatsby";
import {
  GatsbyImage,
  GatsbyImageProps,
  StaticImage,
} from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import styled from "styled-components";
import SEO from "../components/template/seo";
import ContentBlocks from "../components/content-blocks/content-blocks";

const Image = styled(GatsbyImage)<GatsbyImageProps>`
  max-width: 750px;
`;
const Title = styled.h1`
  margin: 0 0 0.3em;
  font-weight: 400;
`;
const Date = styled.div`
  margin: 0 0 1.2em;
`;
const Header = styled.div`
  margin: 2em 0 0;
`;
const Back = styled(AniLink)`
  margin: 0 0 0.5em;
  display: inline-block;
`;

const Post = ({ data }) => {
  const { wpPost: post } = data;

  return (
    <>
      <SEO data={post.seo} />

      <div className="container">
        <Header>
          <Back to="/articles">Articles &raquo;</Back>
          <Title>{post.title}</Title>
          <Date>{post.date}</Date>

          {post.featuredImage && (
            <Image
              image={
                post.featuredImage.node.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt={post.featuredImage.node.altText}
            />
          )}
        </Header>
      </div>

      {post.acfContentBlocks && (
        <ContentBlocks blocks={post.acfContentBlocks.contentBlocks} />
      )}
    </>
  );
};

export default Post;

export const pageQuery = graphql`
  query post($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      date(formatString: "Do MMMM YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1920, quality: 80)
            }
          }
        }
      }
      seo {
        ...SEO
      }
      acfContentBlocks {
        ...ContentBlocksPost
      }
    }
  }
`;
